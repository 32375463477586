import React from 'react'
import ContactSection from '../components/ContactSection'
import PageSpace from '../components/PageSpace'
import ParagraphText from '../components/typography/ParagraphText'
import { RegularTitle, SectionTitle } from '../components/typography/Title'
function ContactPage() {
    return (
        <PageSpace top={100} bottom={100}>
            <div className='container' style={{textAlign: 'center'}}>
                <SectionTitle>Dear Customer,
</SectionTitle>
                <RegularTitle>Thank you for getting your services at our company. We would like to know how we performed. Please spare some moments to give us your valuable feedback as it will help us in improving our services.</RegularTitle>
                <br/>
            </div>
            <div className='container'>
            <ContactSection isFeedback={true}/>
            </div>
        </PageSpace>
    )
}

export default ContactPage
