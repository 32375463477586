import React, { useRef, useState } from 'react'
import { ContactSectionStyle } from '../styles/ContactSectionStyle'
import emailjs from '@emailjs/browser';
import {toast} from 'react-toastify';
import {FaStar} from 'react-icons/fa'

function ContactSection({isFeedback}) {
    const [name, setName] = useState('')
    const [mobileNumber, setNumber] = useState("")
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('');
    const [ratingIndex, setRating] = useState(0);
    const form = useRef()

    async function HandleSubmit(e){
        e.preventDefault();
        // console.log(form.current)

        if(ratingIndex === 0 && isFeedback){
            return toast.warn("Error! no Rating!")
        }

        if(isFeedback){
            if(ratingIndex === 0 ){
                return toast.warn("Error! no Rating!")
            }

            if(message){
                emailjs.sendForm('service_wcl6hfi', 'template_vmgf3c7', form.current, '8Q2K_Ku8lTC4ygO1M')
            .then((result) => {
                toast("message sent")
                // alert("message sent");
                setName("")
                setEmail("")
                setMessage("")
                setNumber("")
            }, (error) => {
                toast.error(error.text);
                // alert(error.text)
            });
            }
        }else{
            if(name !== "" && email !== "" && message !== ""){
                emailjs.sendForm('service_wcl6hfi', 'template_vmgf3c7', form.current, '8Q2K_Ku8lTC4ygO1M')
                .then((result) => {
                    toast("message sent")
                    // alert("message sent");
                    setName("")
                    setEmail("")
                    setMessage("")
                    setNumber("")
                }, (error) => {
                    toast.error(error.text);
                    // alert(error.text)
                });   
            }else{
                toast.warn('fill all the required field !')
            }
        }

        
    } 

    return (
        <ContactSectionStyle>
            <div className="screen">
                <div className="screen-header">
                    <div className="screen-header-left">
                    <div className="screen-header-button close"></div>
                    <div className="screen-header-button maximize"></div>
                    <div className="screen-header-button minimize"></div>
                    </div>
                    <div className="screen-header-right">
                    <div className="screen-header-ellipsis"></div>
                    <div className="screen-header-ellipsis"></div>
                    <div className="screen-header-ellipsis"></div>
                    </div>
                </div>
                <div className="screen-body">
                    <div className="screen-body-item left">
                    <div className="app-title">
                        {!isFeedback ? <><span>Contact  </span>
                        <span>Us</span></> : <>
                        <span>Feed Back</span>
                        </>}
                    </div>
                    <div className="app-contact">
                        <div >
                        Contact Info : +243 992 625 960 
                        </div>
                        <div >
                        Mail : auctux@gmail.com
                        </div>
                    </div>

                    </div>
                    <div className="screen-body-item">
                    {isFeedback ? <form ref={form} className="app-form" role="form">
                        <div className="app-form-group">
                            <h2 style={{fontWeight: "400", marginBottom: "8px", color: "#bbb"}}>Rate the quality of our services</h2>
                            <div style={{display: "flex", flexDirection: "row"}}>
                            <div>
                                <FaStar onClick={()=>setRating(1)} className='starRating' style={ ratingIndex > 0 ? {color: "orange"} : {color: "gray"}} size={40}/>
                            </div>
                            <div>
                                <FaStar onClick={()=>setRating(2)} className='starRating' style={ ratingIndex > 1 ? {color: "orange"} : {color: "gray"}} size={40}/>
                            </div>
                            <div>
                                <FaStar onClick={()=>setRating(3)} className='starRating' style={ ratingIndex > 2 ? {color: "orange"} : {color: "gray"}} size={40}/>
                            </div>
                            <div>
                                <FaStar onClick={()=>setRating(4)} className='starRating' style={ ratingIndex > 3 ? {color: "orange"} : {color: "gray"}} size={40}/>
                            </div>
                            <div>
                                <FaStar onClick={()=>setRating(5)} className='starRating' style={ ratingIndex > 4 ? {color: "orange"} : {color: "gray"}} size={40}/>
                            </div>
                            </div>
                        </div>

                        <input name="user_name" value={`-- Rating: ${ratingIndex} stars`} hidden />
                        <input name="user_email" value={"auctux@feedback.com"} hidden />

                        
                        <div className="app-form-group message">
                        <textarea name="message" value={message} onChange={(e)=>setMessage(e.target.value)} className="app-form-control" placeholder="Share your experience with auctux" required/>
                        </div>
                        <div  className="app-form-group">
                        <textarea name="user_number" value={mobileNumber} onChange={(e)=>setNumber(e.target.value)} className="app-form-control" type='text' placeholder="How can we improve" />
                        </div>
                        <div className="app-form-group buttons">
                        <button onClick={HandleSubmit} onKeyDown={HandleSubmit} className="app-form-button">send</button>
                        </div>
                        
                    </form> : <form ref={form} className="app-form" role="form">
                        <div className="app-form-group">
                        <input name="user_name" value={name} onChange={(e)=>setName(e.target.value)} className="app-form-control" placeholder="Full Name" required/>
                        </div>
                        <div  className="app-form-group">
                        <input name="user_email" value={email} onChange={(e)=>setEmail(e.target.value)} className="app-form-control" type='email' placeholder="Email" required/>
                        </div>
                        <div  className="app-form-group">
                        <input name="user_number" value={mobileNumber} onChange={(e)=>setNumber(e.target.value)} className="app-form-control" type='number' placeholder="Mobile Number" />
                        </div>
                        <div className="app-form-group message">
                        <textarea name="message" value={message} onChange={(e)=>setMessage(e.target.value)} className="app-form-control" placeholder="Message" required/>
                        </div>
                        <div className="app-form-group buttons">
                        <button onClick={HandleSubmit} onKeyDown={HandleSubmit} className="app-form-button">send</button>
                        </div>
                    </form>}
                    </div>
                </div>
                </div>
                
        </ContactSectionStyle>
    )
}

export default ContactSection
